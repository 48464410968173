import React, {
  useState,
  Suspense,
  useEffect,
  useMemo,
  useContext,
} from "react";
import Header from "./Header";
import SideBar from "../../components/SideBar";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/styles";
import AppLoading from "../../components/Loading";
import Breadcrumb from "./Breadcrumb";
import { AuthContext } from "../AuthProvider";
import { adminPrefix, AdminRoutes } from "../../routes/AdminRoutes";
import HomeIcon from "@material-ui/icons/Home";
import DialogProvider from "../../components/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import { LayoutScreen } from "../../common/text.json";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { role_id, sidebardWidth } from "../../common/constants";
import clsx from "clsx";
import { Paper } from "@material-ui/core";
import { peoplePrefix, PeopleRoutes } from "../../routes/PeopleRoutes";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CategoryIcon from "@material-ui/icons/Category";
import DescriptionIcon from "@material-ui/icons/Description";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import BarChartIcon from "@material-ui/icons/BarChart";
import AlarmIcon from "@material-ui/icons/Alarm";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as System } from "../../assets/icons/system.svg";
import { ReactComponent as Category } from "../../assets/icons/category.svg";
import { ReactComponent as File } from "../../assets/icons/file.svg";
import { ReactComponent as NCC } from "../../assets/icons/ncc.svg";
import { ReactComponent as Document } from "../../assets/icons/document.svg";
import { ReactComponent as Hotro } from "../../assets/icons/hotro.svg";
import { ReactComponent as Chart } from "../../assets/icons/chart.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import TimerIcon from "@material-ui/icons/Timer";
import KitchenOutlinedIcon from "@material-ui/icons/KitchenOutlined";
import LocalPlayIcon from "@material-ui/icons/LocalPlay";
import GetAppIcon from "@material-ui/icons/GetApp";

const Layout = React.memo((props) => {
  const [open, setOpen] = useState(
    localStorage.getItem("sidebar")
      ? JSON.parse(localStorage.getItem("sidebar"))
      : false
  );
  useEffect(() => {
    localStorage.setItem("sidebar", open);
  }, [open]);
  const location = useLocation();
  const [path, setPath] = useState("");
  const { adminRoutes } = AdminRoutes();
  const { peopleRoutes } = PeopleRoutes();
  const classes = useStyle();
  const { admin, perms, user } = useContext(AuthContext);
  const [defaultAccount, setDefaultAccount] = useState([
    {
      path: adminPrefix + "/user",
      label: LayoutScreen.user,
      perm: "user_list",
    },
    {
      path: adminPrefix + "/role",
      label: LayoutScreen.role,
      perm: "role_list",
    },
    {
      path: adminPrefix + "/setting",
      label: LayoutScreen.setting,
      perm: "setting_edit",
    },
    {
      path: adminPrefix + "/audit",
      label: LayoutScreen.audit,
      perm: "audit_list",
    },
    {
      path: adminPrefix + "/display",
      label: LayoutScreen.display,
      perm: "display_setting",
    },
    {
      path: adminPrefix + "/helperFile",
      label: LayoutScreen.helperFile,
      perm: "file_setting",
    },
    {
      path: adminPrefix + "/data-synchronization",
      label: LayoutScreen.data_synchronization,
      hide:
        admin?.role_id != role_id?.ADMIN &&
        admin?.role_id != role_id?.DEPARTMENT_ALL,
    },
    {
      path: adminPrefix + "/day-off",
      label: "Cài đặt ngày nghỉ",
      perm: "day_off_setting",
    },
  ]);
  useEffect(() => {
    let temp = [...defaultAccount];
    if(admin?.role_id == role_id.ADMIN ||
      admin?.role_id == role_id.DEPARTMENT_ALL) {
      temp.push({
        path: adminPrefix + "/ndPlace",
        label: "Địa điểm điều dưỡng",
        perm: "",
      })
    }
    if (
      admin?.role_id != role_id.ADMIN &&
      admin?.role_id != role_id.DEPARTMENT_ALL
    ) {
      temp.push({
        path: adminPrefix + "/default-account",
        label: "Cài đặt tài khoản tiếp nhận",
        perm: "",
      });
    }
    if (admin?.role_id == role_id.DEPARTMENT_PROVINCE) {
      temp.push({
        path: adminPrefix + "/authority-pay-file",
        label: "Ủy quyền giải quyết hồ sơ",
        perm: "",
      });
    }
    setDefaultAccount(temp);
  }, [admin]);

  function CheckPerm(permRoute) {
    if (!permRoute) return true;
    if (Array.isArray(permRoute)) {
      for (let e in permRoute) {
        if (!perms.map((c) => c.name).includes(permRoute[e])) {
          return false;
        }
      }
      return true;
    } else {
      return perms?.map((e) => e.name).includes(permRoute);
    }
  }
  const prefix = () => {
    if (admin) return adminPrefix;
    if (user) return peoplePrefix;
    return null;
  };

  const routes = useMemo(() => {
    if (admin) {
      return adminRoutes;
    }
    if (user) {
      if (!user?.name) {
        return peopleRoutes.filter((e) => e.path == "/update-info");
      }
      return peopleRoutes;
    }
    return [];
  }, [adminRoutes]);
  useEffect(() => {
    if (location.pathname == prefix()) setPath("/");
  }, [location.pathname, path]);

  function render(route) {
    const permRoute = route?.perm;
    if (CheckPerm(permRoute) && !route?.hide) {
      return (
        <Route
          path={prefix() + route.path}
          exact
          key={route.path}
          render={(_) => {
            setPath(route.path);
            return (
              <DialogProvider>
                <route.component />
              </DialogProvider>
            );
          }}
        />
      );
    }
  }

  const sidebarPeople = [
    {
      label: LayoutScreen.home,
      icon: <HomeIcon />,
      perm: "",
      path: peoplePrefix + "/",
    },
    {
      label: "Hồ sơ Người có công",
      icon: <PersonIcon />,
      perm: "",
      path: peoplePrefix + "/devoteePeopleFile",
    },
    {
      label: "Hồ sơ thân nhân Người có công",
      icon: <PeopleAltIcon />,
      perm: "",
      path: peoplePrefix + "/relativePeopleFile",
    },
  ];

  const sidebarAdmin = [
    //home
    {
      label: LayoutScreen.home,
      icon: HomeIcon,
      perm: "",
      path: adminPrefix + "/",
    },
    {
      label: LayoutScreen.system_management,
      icon: DashboardIcon,
      perm: [
        "user_list",
        "role_list",
        "setting_edit",
        "audit_list",
        "display_setting",
        "file_setting",
      ],
      children: defaultAccount,
    },
    {
      label: LayoutScreen.category_management,
      icon: CategoryIcon,
      perm: [
        "devotee_type_list",
        "region_list",
        "ethnic_list",
        "original_list",
        "relative_type_list",
        "relative_type_list",
        "welfare_regime_type_list",
        "religion_list",
        "medal_list",
        "rank_list",
        "work_unit_list",
        "position_list",
        "cematery_list",
        "document_type_list",
      ],
      children: [
        {
          path: adminPrefix + "/devoteeType",
          label: LayoutScreen.devotee_type,
          perm: "devotee_type_list",
        },
        {
          path: adminPrefix + "/region",
          label: LayoutScreen.region,
          perm: "region_list",
        },
        {
          path: adminPrefix + "/ethnic",
          label: LayoutScreen.ethnic,
          perm: "ethnic_list",
        },
        {
          path: adminPrefix + "/originalRegion",
          label: LayoutScreen.original_region,
          perm: "original_list",
        },
        {
          path: adminPrefix + "/relativeType",
          label: LayoutScreen.relative_type,
          perm: "relative_type_list",
        },
        {
          path: adminPrefix + "/welfareRegimeType",
          label: LayoutScreen.welfare_regime_type,
          perm: "welfare_regime_type_list",
        },
        {
          path: adminPrefix + "/religion",
          label: LayoutScreen.religion,
          perm: "religion_list",
        },
        {
          path: adminPrefix + "/medal",
          label: LayoutScreen.medal,
          perm: "medal_list",
        },

        {
          path: adminPrefix + "/rank",
          label: LayoutScreen.rank,
          perm: "rank_list",
        },
        {
          path: adminPrefix + "/work-unit",
          label: LayoutScreen.work_unit,
          perm: "work_unit_list",
        },
        {
          path: adminPrefix + "/battlefield",
          label: "Danh sách chiến trường",
          perm: "work_unit_list",
        },
        {
          path: adminPrefix + "/position",
          label: LayoutScreen.position,
          perm: "position_list",
        },

        {
          path: adminPrefix + "/cematery",
          label: LayoutScreen.cematery,
          perm: "cematery_list",
        },
        {
          path: adminPrefix + "/documentType",
          label: LayoutScreen.document_type,
          perm: "document_type_list",
        },
        {
          path: adminPrefix + "/gddt",
          label: "Trợ cấp giáo dục",
          perm: "",
        },
      ],
    },
    {
      label: LayoutScreen.profile_setting,
      icon: DescriptionIcon,
      perm: [
        "welfare_regime_list",
        "devotee_type_document_relation_view",
        "relative_type_document_relation_view",
      ],
      children: [
        {
          path: adminPrefix + "/welfare_regime",
          label: LayoutScreen.welfare_regime,
          perm: "welfare_regime_list",
        },
        {
          path: adminPrefix + "/monthlyWelfareRegime",
          label: "Trợ cấp hàng tháng",
          perm: "monthly_welfare_regime_list",
        },
        {
          path: adminPrefix + "/fileType",
          label: LayoutScreen.file_type,
          perm: "file_type_list",
        },
        {
          path: adminPrefix + "/devoteeTypeFile",
          label: LayoutScreen.devotee_file_type,
          perm: "devotee_type_document_relation_view",
        },
        {
          path: adminPrefix + "/relativeTypeFile",
          label: LayoutScreen.relative_file_type,
          perm: "relative_type_document_relation_view",
        },
      ],
    },
    {
      label: LayoutScreen.devotee_management,
      icon: SupervisorAccountIcon,
      perm: "devotee_information_list",
      children: [
        {
          path: adminPrefix + "/devotee-information",
          label: LayoutScreen.devotee_information,
          perm: "devotee_information_list",
        },
        {
          path: adminPrefix + "/martyrs",
          label: "Liệt sĩ",
          perm: "devotee_information_list",
        },
        {
          path: adminPrefix + "/graves",
          label: "Mộ liệt sĩ",
          perm: "devotee_information_list",
        },
      ],
    },
    {
      label: "Hồ sơ",
      icon: AssignmentIndIcon,
      perm: ["relative_file_list", "devotee_file_list"],
      children: [
        {
          path: adminPrefix + "/devoteeFileList",
          label: "Hồ sơ người có công",
          perm: "devotee_file_list",
        },
        {
          path: adminPrefix + "/relativeFileList",
          label: "Hồ sơ thân nhân người có công",
          perm: "relative_file_list",
        },
      ],
    },
    {
      label: "Hỗ trợ giải quyết chế độ",
      icon: HeadsetMicIcon,
      perm: "",
      children: [
        {
          path: adminPrefix + "/devoteePay",
          label: "Hỗ trợ giải quyết chế độ cho người có công",
          perm: "",
        },
        {
          path: adminPrefix + "/relativePay",
          label: "Hỗ trợ giải quyết chế độ thân nhân người có công",
          perm: "",
        },
      ],
    },
    {
      label: "Thống kê NCC",
      icon: BarChartIcon,
      perm: "",
      children: [
        {
          path: adminPrefix + "/normalReport",
          label: "Báo cáo/Thống kê NCC",
          perm: "",
        },
        {
          path: adminPrefix + "/reportInOutInMonth",
          label: "Báo cáo/Thống kê tăng giảm theo tháng",
          perm: "",
        },
      ],
    },
    {
      label: "Thống kê hồ sơ hỗ trợ",
      icon: AlarmIcon,
      perm: "",
      children: [
        {
          path: adminPrefix + "/over-date-pay-file",
          label: "Báo cáo danh sách hồ sơ quá hạn",
          perm: "",
        },
        {
          path: adminPrefix + "/pay-file-report",
          label: "Thống kê hồ sơ giải quyết chế độ",
          perm: "",
        },
        {
          path: adminPrefix + "/pay-file-report-in-month",
          label: "Thống kê hồ sơ giải quyết chế độ theo tháng",
          perm: "",
        },
        {
          path: adminPrefix + "/pay-file-report-by-account",
          label: "Thống kê hồ sơ giải quyết chế độ theo người giải quyết",
          perm: "",
        },
      ],
    },
    {
      label: "Báo cáo người hưởng tuất nuôi dưỡng",
      icon: LocalPlayIcon,
      perm: "",
      children: [
        {
          path: adminPrefix + "/nurture-subsidies-ltcm",
          label: "Tuất nuôi dưỡng LTCM",
          perm: "",
        },
        {
          path: adminPrefix + "/nurture-subsidies-tkn",
          label: "Tuất nuôi dưỡng TKN",
          perm: "",
        },
        {
          path: adminPrefix + "/nurture-subsidies-tb",
          label: "Tuất nuôi dưỡng TB",
          perm: "",
        },
        {
          path: adminPrefix + "/nurture-subsidies-bb",
          label: "Tuất nuôi dưỡng BB",
          perm: "",
        },
        {
          path: adminPrefix + "/nurture-subsidies-hh",
          label: "Tuất nuôi dưỡng HH",
          perm: "",
        },
        {
          path: adminPrefix + "/nurture-subsidies-ls",
          label: "Tuất nuôi dưỡng LS",
          perm: "",
        },
        {
          path: adminPrefix + "/nurture-subsidies-ncc",
          label: "Tuất nuôi dưỡng NCC",
          perm: "",
        },
      ],
    },
    {
      label: "Tải xuống báo cáo",
      icon: GetAppIcon,
      perm: "",
      children: [
        {
          path: adminPrefix + "/form-export-1",
          label:
            "Báo cáo số lượng đối tượng đang hưởng trợ cấp, phụ cấp ưu đãi hàng tháng",
          perm: "",
        },
        {
          path: adminPrefix + "/form-export-2",
          label:
            "Báo cáo số lượng đối tượng thương binh, thương binh B, người hưởng chính sách như thương binh đang hưởng trợ cấp tháng",
          perm: "",
        },
        {
          path: adminPrefix + "/form-export-3",
          label: "Báo cáo số lượng đối tượng hưởng trợ cấp ưu đãi giáo dục",
          perm: "",
        },
        {
          path: adminPrefix + "/form-export-4",
          label: "Báo cáo số lượng đối tượng được mua bảo hiểm y tế",
          perm: "",
        },
        {
          path: adminPrefix + "/form-export-6",
          label:
            "Báo cáo số lượng đối tượng đang được nuôi dưỡng tại cơ sở nuôi dưỡng",
          perm: "",
        },
        {
          path: adminPrefix + "/form-export-8-1",
          label: "Quà tặng CTN Tết",
          perm: "",
        },
        {
          path: adminPrefix + "/form-export-8-2",
          label: "Quà tặng CTN 27/7",
          perm: "",
        },
        // {
        //   path: adminPrefix + "/form-export-7",
        //   label: "Chế độ điều dưỡng với người có công với cách mạng",
        //   perm: "",
        // },
        {
          path: adminPrefix + "/form-export-orthopedic-tools",
          label: "Báo cáo trang cấp dụng cụ chỉnh hình",
          perm: "",
        },
      ],
    },
  ];
  const crumbs = useMemo(() => {
    const destructPath = [
      "/",
      ...path
        .split("/")
        .slice(1)
        .map((path) => "/" + path)
        .reduce((a, c) => [...a, a.length ? a[a.length - 1] + c : c], []),
    ];
    const crumbNames = routes
      .filter((route) => destructPath.includes(route.path))
      .map((route) => {
        return {
          name: route.name,
          path: route.path,
        };
      });

    return crumbNames.map((crum, index) => ({
      name: crum.name,
      path: crum.path,
    }));
  }, [location.pathname, path, routes, sidebarAdmin, sidebarPeople]);

  const sidebarList = () => {
    if (admin) {
      return sidebarAdmin;
    } else if (user) {
      return sidebarPeople;
    }
    return [];
  };

  const hideSideBar = user && !user?.name;
  return (
    <div className={classes.root}>
      <DialogProvider>
        <Header open={open} setOpen={setOpen} />
      </DialogProvider>
      {hideSideBar ? (
        <></>
      ) : (
        <>
          <div
            style={{
              zIndex: 1,
            }}
          >
            <SideBar
              sidebarList={sidebarList()}
              setOpen={setOpen}
              open={open}
            />
          </div>
          <Paper
            elevation={6}
            className={clsx(classes.toggleBtn, {
              [classes.toggleBtnOpen]: open,
              [classes.toggleBtnClose]: !open,
            })}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? (
              <>
                <ChevronLeftIcon style={{ width: 18, height: 18 }} />
              </>
            ) : (
              <>
                <ChevronRightIcon style={{ width: 18, height: 18 }} />
              </>
            )}
          </Paper>
        </>
      )}
      <main
        className={`${classes.content} ${
          !hideSideBar && (open ? classes.sidebarOpen : classes.sidebarClose)
        }`}
        style={{ marginTop: 15 }}
      >
        <Toolbar className={classes.toolbar}></Toolbar>
        <Breadcrumb crumbs={crumbs} prefix={prefix()} />
        <Suspense fallback={<AppLoading />}>
          <Switch>
            {routes.map((e) => render(e))}
            {user && !user?.name && (
              <Route path={peoplePrefix}>
                <Redirect to={peoplePrefix + "/update-info"} />
              </Route>
            )}
          </Switch>
        </Suspense>
      </main>
    </div>
  );
});

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    height: 64,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
  },
  sidebarOpen: {
    maxWidth: `calc(100vw - ${sidebardWidth}px)`,
  },
  sidebarClose: {
    maxWidth: `calc(100vw - 70px)`,
  },
  toggleBtn: {
    padding: "2px 2px",
    cursor: "pointer",
    position: "fixed",
    zIndex: 100,
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 30,
    borderRadius: "50%",
    // opacity: 0.7,
    "&:hover": {
      background: theme.palette.custom.secondary,
      "& svg": {
        color: "#fff",
      },
    },
  },
  toggleBtnOpen: {
    top: 89,
    left: 284,
  },
  toggleBtnClose: {
    top: 89,
    left: 45,
  },
}));
export default Layout;
